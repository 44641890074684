"use strict";

$(function () {
  $('.logo').viewportChecker({
    classToAdd: 'animate__animated animate__bounceInDown',
    classToRemove: 'invisible',
    offset: 200,
    repeat: false
  });
  $('.content > *').addClass('invisible');
  $('.content > *').viewportChecker({
    classToAdd: 'animate__animated animate__fadeInRight',
    classToRemove: 'invisible',
    offset: 200,
    repeat: false
  });
  var email = window.atob('bmFyZ2lzQGN1cnRlYW51LmNvbQ==');
  $('.mailto').attr('href', 'mailto:' + email).text(email);
});